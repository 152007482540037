<template>
  <div class="gites container">
    <h1 class="my-4 h1">Découvrez nos gîtes à Tournai, <br>prêts à vous accueillir !</h1>
    <p class="lead">
        Tous nos gîtes sont équipés d'internet en wi-fi, d'un espace cuisine, et d'un parking intérieur privé.
        <br><small>Les animaux ne sont pas admis.</small>
    </p>
    <div class="row row-cols-1 row-cols-md-3 shadow rounded py-4 my-3">
      <CarteGite v-for="gite in gites" :key="gite.id" :gite="gite" />
    </div>
  </div>
</template>

<script>
import CarteGite from '@/components/CarteGite.vue'

export default {
  name: 'Gites',
  components: {
    CarteGite
  },
  computed: {
    gites () {
      return this.$store.state.gites
    }
  }
}
</script>
